import React, { useEffect } from "react";
import { Container, Button, Alert, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import { isValidEmailAddress } from "../utils";

function UserDetails() {
  const UPDATE_USER = gql`
    mutation changePlatformUser(
      $id: ID!
      $firstname: String!
      $surname: String!
      $email: String!
      $role: String!
    ) {
      changePlatformUser(
        id: $id
        firstname: $firstname
        surname: $surname
        email: $email
        role: $role
      ) {
        firstname
        surname
        email
        role
      }
    }
  `;
  const DELETE_USER = gql`
  mutation deletePlatformUser($id: ID!) {
    deletePlatformUser(id: $id)
  }
`;

const RESET_PASSWORD = gql`
  mutation resetPassword(
    $email: String!
    $passwordNew: String!
  ) {
    resetPassword(
      email: $email
      newPassword: $passwordNew
    ) {
      firstname
      surname
    }
  }
`;
  const [validNavigation, setValidNavigation] = React.useState(true);
  const location = useLocation();

  const [id, setId] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [responseMessage, setResponseMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [responseMessageResetPassword, setResponseMessageResetPassword] = React.useState("");
  const [alertTypeResetPassword, setAlertTypeResetPassword] = React.useState("");
  const [updatePlatformUser, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      setAlertType("success");
      setResponseMessage(
        "Die Änderungen wurden gespeichert. Veränderte Rollen werden für den betreffenden Benutzer erst nach einem erneuten Login wirksam."
      );
    },
    onError: (error) => {
      console.error("Error during PlatformUser update attempt", error);
      setAlertType("danger");
      setResponseMessage(error.message);
    },
  });
  const [deletePlatformUser, { loading: loadingDeletion }] = useMutation(
    DELETE_USER,
    {
      onCompleted: (data) => {
        window.history.replaceState({}, "")
        setFirstname("");
        setSurname("");
        setEmail("");
        setRole("NOROLE")

        setAlertType("success");
        setResponseMessage(data.deletePlatformUser);
      },
      onError: (error) => {
        console.error("Error during student deletion attempt", error);
        setAlertType("danger");
        setResponseMessage(error.message);
      },
    }
  );
  const [passwordNew, setPasswordNew] = React.useState("");
  const [passwordNewAgain, setPasswordNewAgain] = React.useState("");
  const [resetPassword, { loading: resetPasswordLoading }] = useMutation(RESET_PASSWORD, {
    onCompleted: (data) => {
      setPasswordNew("");
      setPasswordNewAgain("");
      setAlertTypeResetPassword("success");
      setResponseMessageResetPassword(
        "Das Password wurde für " + data.resetPassword.firstname + " " + data.resetPassword.surname + " erfolgreich geändert."
      );
    },
    onError: (error) => {
      console.error("Error during password change attempt", error);
      setAlertTypeResetPassword("danger");
      setResponseMessageResetPassword(error.message);
    },
  });

  function handleResetPassword(event) {
    event.preventDefault();
    if (passwordNew !== passwordNewAgain) {
      setAlertTypeResetPassword("warning");
      setResponseMessageResetPassword(
        "Die eingegebenen neuen Passwörter stimmen nicht überein"
      );
    } else {
      resetPassword({
        variables: {
          email: email,
          passwordNew: passwordNew,
        },
      });
    }
  }

  useEffect(() => {
    try {
      const platformUser = location.state.platformUsers;
      setId(platformUser.id);
      setFirstname(platformUser.firstname);
      setSurname(platformUser.surname);
      setEmail(platformUser.email);
      setRole(platformUser.role);
    } catch (error) {
      console.error(error);
      setValidNavigation(false);
    }
  }, [location]);

  function handleUpdateUser(event) {
    event.preventDefault();

    //Check not empty
    try {
      [firstname, surname, email, role].forEach((element) => {
        if (isEmpty(element)) {
          throw new Error("Alle Eingabefelder müssen ausgefüllt sein");
        }
      });
    } catch (error) {
      setAlertType("danger");
      setResponseMessage(error.message);
      return;
    }

    //Check role types
    const aValidRoles = ["ADMIN", "INSTRUCTOR", "RESEARCHER", "NOROLE"];
    if (!aValidRoles.includes(role)) {
      setAlertType("danger");
      setResponseMessage("Die ausgewählt Rolle ist nicht gültig");
      return;
    }

    //Check valid mail address
    if (!isValidEmailAddress(email)) {
      setAlertType("danger");
      setResponseMessage("Das ist keine gültige E-Mail-Adresse");
      return;
    }

    updatePlatformUser({
      variables: {
        id: id,
        firstname: firstname,
        surname: surname,
        email: email,
        role: role,
      },
    });
  }

  function handleDelete() {
    const bConfirmDeletion = window.confirm(
      "Soll " + firstname + " " + surname + " endgültig gelöscht werden?"
    );
    if (bConfirmDeletion) {
      deletePlatformUser({
        variables: {
          id: id,
        },
      });
    } else {
      setAlertType("info");
      setResponseMessage("Löschung wurde abgebrochen");
    }
  }

  function isEmpty(sValue) {
    if (sValue === "") {
      return true;
    } else {
      return false;
    }
  }

  if (validNavigation) {
    return (
      <Container>
        <h2>
          Detailansicht für {firstname} {surname}
        </h2>
        <Form onSubmit={handleUpdateUser}>
          <Form.Group className="mb-3" controlId="formBasicFirstname">
            <Form.Label>Vorname</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder=""
              value={firstname}
              onChange={(event) => setFirstname(event.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicSurname">
            <Form.Label>Nachname</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder=""
              value={surname}
              onChange={(event) => setSurname(event.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicSurname">
            <Form.Label>E-Mail-Adresse</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder=""
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicSurname">
            <Form.Label>Rolle</Form.Label>
            <Form.Control
              as="select"
              value={role}
              defaultChecked={true}
              onChange={(event) => {
                setRole(event.target.value);
              }}
            >
              <option value="NOROLE">Keine Rolle</option>
              <option value="INSTRUCTOR">INSTRUCTOR</option>
              <option value="RESEARCHER">RESEARCHER</option>
              <option value="ADMIN">ADMIN</option>
            </Form.Control>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={loading}
            className="mb-3"
          >
            Speichern
          </Button>{" "}
          <Button
            variant="danger"
            className="mb-3"
            disabled={loadingDeletion}
            onClick={handleDelete}
          >
            Löschen
          </Button>{" "}
          {responseMessage && (
            <Alert variant={alertType}>{responseMessage}</Alert>
          )}
        </Form>
        <hr className="mb-4"/>
        <h3>Passwort ändern</h3>
        <p>Sollte ein Benutzer sein Passwort vergessen haben, können Sie als Admin für den Benutzer ein neues Passwort vergeben. Dieses sollte der Benutzer nach dem nächsten Login ändern.</p>
        
        <Form onSubmit={handleResetPassword}>

            <Form.Group className="mb-3" controlId="formBasicMailNew">
              <Form.Label>Neues Passwort</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder=""
                value={passwordNew}
                onChange={(event) => setPasswordNew(event.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicMailNewAgain">
              <Form.Label>Neues Passwort wiederholen</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder=""
                value={passwordNewAgain}
                onChange={(event) => setPasswordNewAgain(event.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={resetPasswordLoading}
              className="mb-3"
            >
              Passwort ändern
            </Button>
            <Alert variant={alertTypeResetPassword}>{responseMessageResetPassword}</Alert>
          </Form>
        
        <hr className="mb-4"/>
        <Button className="mb-5" href="/user">Zurück</Button>
      </Container>
    );
  } else {
    return (
      <Container>
        <Alert variant="danger">
          <Alert.Heading>Ups! Ungültige Navigation</Alert.Heading>
          <p>
            <Alert.Link href="/">Hier</Alert.Link> geht es zurück zur Startseite
          </p>
        </Alert>
      </Container>
    );
  }
}

export default UserDetails;
