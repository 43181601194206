import React from "react";
import {
  Form,
  Button,
  Container,
  Alert,
  Col,
  Row,
  Card,
} from "react-bootstrap";
import { useMutation, gql } from "@apollo/client";
import {handleQrCodeDownload} from "../utils";

const ADD_STUDENT = gql`
  mutation createStudent($firstname: String!, $surname: String!) {
    createStudent(firstname: $firstname, surname: $surname) {
      id
      qrCode
    }
  }
`;

function AddStudent() {
  const [responseMessage, setResponseMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("warning");
  const [firstname, setFirstname] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [addedFirstName, setAddedFirstname] = React.useState("");
  const [addedSurname, setAddedSurname] = React.useState("");
  const [qrCode, setQrCode] = React.useState("");
  const [addStudent, { loading }] = useMutation(ADD_STUDENT, {
    onCompleted: (data) => {
      setAddedFirstname(firstname);
      setAddedSurname(surname);
      setFirstname("");
      setSurname("");
      setQrCode(data.createStudent.qrCode);
      setAlertType("success");
      setResponseMessage(
        firstname +
          " " +
          surname +
          " wurde mit ID " +
          data.createStudent.id +
          " angelegt"
      );
    },
    onError: (error) => {
      console.error("Error during addStudent attempt", error);
      setAlertType("danger");
      setResponseMessage(error.message);
    },
  });

  function handleAddStudent(event) {
    event.preventDefault();
    addStudent({ variables: { firstname: firstname, surname: surname } });
  }

  return (
    <Container>
      <h2>Teilnehmer:in der Organisation hinzufügen</h2>
      <p>
        Hier können Sie Schülerinnen und Schüler die Organisation hinzufügen.
        Nach dem erfolgreichen Hinzufügen, wird der QR-Code angezeigt. Diesen
        können Sie direkt herunterladen oder auch jederzeit in der
        Übersichtseite einsehen. Für eine bessere Zuordnung enthält der
        Dateiname des QR-Codes den Namen der Schülerin bzw. des Schülers. Der
        QR-Code wird benötigt, damit sich die Schülerinnen und Schüler an der
        mobilen SRL App einloggen können.
      </p>
      <hr className="mb-4" />
      <Row>
        <Col>
          <Form onSubmit={handleAddStudent}>
            <Form.Group className="mb-3" controlId="formBasicFirstname">
              <Form.Label>Vorname</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                value={firstname}
                onChange={(event) => setFirstname(event.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicSurname">
              <Form.Label>Nachname</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                value={surname}
                onChange={(event) => setSurname(event.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={loading}
              className="mb-3"
            >
              Teilnehmer:in hinzufügen
            </Button>
            {responseMessage && (
              <Alert variant={alertType}>{responseMessage}</Alert>
            )}
          </Form>
        </Col>
        {qrCode && (
          <Col>
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={qrCode}></Card.Img>
              <Card.Body style={{ textAlign: "center" }}>
                <Card.Title>
                  {addedFirstName} {addedSurname}
                </Card.Title>
                <Card.Link>
                  <Button
                    onClick={() =>
                      handleQrCodeDownload(qrCode, addedFirstName, addedSurname)
                    }
                  >
                    Download
                  </Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
      <hr className="mb-4" />
      <Button className="mb-4" href="/students">Zurück</Button>
    </Container>
  );
}

export default AddStudent;