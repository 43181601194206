import React from "react";
import { Container, Button, Alert, Table } from "react-bootstrap";
import { useQuery, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";

const SESSIONS_LIST = gql`
  query sessionsCreatedBy($createdBy: ID!) {
    sessionsCreatedBy(createdBy: $createdBy) {
      id
      title
      createdAt
      tags
      elements
      student {
        firstname
        surname
      }
      organization {
        name
      }
      createdBy {
        firstname
        surname
      }
    }
  }
`;

function handleSessionDetails(oHistory, oSession) {
  oHistory.push({
    pathname: "/session-details/" + oSession.id,
    state: { session: oSession },
  });
}

function SessionsList() {
  const oHistory = useHistory();
  const { data, error } = useQuery(SESSIONS_LIST, {
    variables: { createdBy: localStorage.getItem("id") },
  });

  return (
    <Container className="mb-3">
      <hr className="mb-4" />
      <h2>Erstellte Sessions</h2>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Titel</th>
            <th>Erstellt am</th>
            <th>Aktion</th>
          </tr>
        </thead>
        <tbody>
          {data && data.sessionsCreatedBy.length > 0 ? (
            <>
              {data.sessionsCreatedBy.map((session) => (
                <tr>
                  <td>{session.title}</td>
                  <td>{session.createdAt}</td>
                  <td>
                    <Button
                      onClick={() => handleSessionDetails(oHistory, session)}
                    >
                      Details
                    </Button>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan="3">
                Sie haben noch keine Sessions erstellt und/oder in der App mit
                dem Server synchronisiert
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {error && <Alert variant="danger">{error.message}</Alert>}
    </Container>
  );
}
export default SessionsList;
