import React, { useEffect } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  Alert,
} from "react-bootstrap";
import { useMutation, gql, useQuery } from "@apollo/client";
import SideNavigation from "./SideNavigation";
import { API_URL } from "./../config";

const GET_ORGANIZATION = gql`
  query Organization($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      name
      systemUrl
    }
  }
`;

const UPDATE_ORGANIZATION_NAME = gql`
  mutation changeOrganizationName($id: ID!, $newName: String!) {
    changeOrganizationName(id: $id, newName: $newName) {
      name
    }
  }
`;

const UPDATE_ORGANIZATION_URL = gql`
  mutation changeOrganizationUrl($id: ID!, $newUrl: String!) {
    changeOrganizationUrl(id: $id, newUrl: $newUrl) {
      systemUrl
    }
  }
`;

async function handleConnectivityTest(sUrl) {
  const sData = JSON.stringify({
    query: `{
      connectivityTest
    }`,
  });

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: sData,
  };
  try {
    const response = await fetch(sUrl, requestOptions);
    const json = await response.json();

    if (json.data.connectivityTest === true) {
      alert(
        "Gültige Verbindung\n\nEin kompatibles SRL Server System ist über die angegebene URL erreichbar und verfügbar."
      );
    }
  } catch (error) {
    alert(
      "Ungültige Verbindung\n\nEs ist kein kompatibles SRL Server System über die angegebene URL erreichbar. Bitte URL und Server prüfen."
    );
    console.error(error);
  }
}

function SettingsOrganization() {
  //Query intial organization data
  const { data } = useQuery(GET_ORGANIZATION, {
    variables: { organizationId: localStorage.getItem("organization_id") },
  });

  const [initialLoading, setInitialLoading] = React.useState(true);
  const [NameResponseMessage, setNameResponseMessage] = React.useState("");
  const [UrlResponseMessage, setUrlResponseMessage] = React.useState("");
  const [NameAlertType, setNameAlertType] = React.useState("warning");
  const [UrlAlertType, setUrlAlertType] = React.useState("warning");
  const [organizationName, setOrganizationName] = React.useState("");
  const [organizationUrl, setOrganizationUrl] = React.useState("");
  const [updateOrganizationName, { loadingName }] = useMutation(
    UPDATE_ORGANIZATION_NAME,
    {
      onCompleted: (data) => {
        setNameAlertType("success");
        setNameResponseMessage(
          "Der Name der Organisation wurde erfolgreich geändert. Der neue Organisationsname lautet: " +
            data.changeOrganizationName.name
        );
      },
      onError: (error) => {
        console.error("Error during organisation name change attempt", error);
        setNameAlertType("danger");
        setNameResponseMessage(error.message);
      },
    }
  );
  const [updateOrganizationUrl, { loadingUrl }] = useMutation(
    UPDATE_ORGANIZATION_URL,
    {
      onCompleted: (data) => {
        setUrlAlertType("success");
        localStorage.setItem(
          "organization_system_url",
          data.changeOrganizationUrl.systemUrl
        );
        setUrlResponseMessage(
          "Die API Url der Organisation wurde erfolgreich geändert. Die neue API Url der Organisation lautet: " +
            data.changeOrganizationUrl.systemUrl
        );
      },
      onError: (error) => {
        console.error(
          "Error during organisation api url change attempt",
          error
        );
        setUrlAlertType("danger");
        setUrlResponseMessage(error.message);
      },
    }
  );

  useEffect(() => {
    if (data) {
      setOrganizationName(data.organization.name);
      setOrganizationUrl(data.organization.systemUrl);
      setInitialLoading(false);
    }
  }, [data]);

  function handleChangeName(event) {
    event.preventDefault();
    if (organizationName === "") {
      setNameAlertType("warning");
      setNameResponseMessage("Der eingegebene Name darf nicht leer sein");
    } else {
      updateOrganizationName({
        variables: {
          id: localStorage.getItem("organization_id"),
          newName: organizationName,
        },
      });
    }
  }

  function handleChangeUrl(event) {
    event.preventDefault();
    if (organizationUrl === "") {
      setUrlAlertType("warning");
      setUrlResponseMessage("Die eingegebene Url darf nicht leer sein");
    } else {
      updateOrganizationUrl({
        variables: {
          id: localStorage.getItem("organization_id"),
          newUrl: organizationUrl,
        },
      });
    }
  }

  return (
    <Container>
      <Row>
        <Col sm={4}>
          <SideNavigation />
        </Col>
        <Col>
          <h2>Einstellungen - Organisation</h2>
          {initialLoading && (
            <Alert variant="info">
              Initiale Daten werden geladen. Vor der Bearbeitung warten, bis
              Daten geladen sind.
            </Alert>
          )}

          <hr className="mb-4" />

          <h3>Name der Organisation</h3>
          <p>Ändern Sie den Namen der Organisation</p>
          <Form onSubmit={handleChangeName}>
            <Form.Group className="mb-3" controlId="formBasicMailOld">
              <Form.Label>Name der Organisation</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                value={organizationName}
                onChange={(event) => setOrganizationName(event.target.value)}
                disabled={initialLoading}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={loadingName || initialLoading}
              className="mb-3"
            >
              Ändern
            </Button>
            {NameResponseMessage && (
              <Alert variant={NameAlertType}>{NameResponseMessage}</Alert>
            )}
          </Form>

          <hr className="mb-4" />

          <h3>API URL der Organisation</h3>
          <p>
            Hierbei handelt es sich um die API URL der Organisation. Diese wird
            benötigt, sodass sich die mobile App mit dem Server der Organisation
            austauschen kann. Diese wird mithilfe der generierten QR-Codes der
            mobile App bekannt gemacht.
          </p>
          <Form onSubmit={handleChangeUrl}>
            <Form.Group className="mb-3" controlId="formBasicMailOld">
              <Form.Label>API URL</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                value={organizationUrl}
                onChange={(event) => setOrganizationUrl(event.target.value)}
                disabled={initialLoading}
              />
              {organizationUrl !== API_URL && (
                <>
                  <Alert className="my-2" variant="danger">
                    Die im Deployment konfigurierte API Url ist noch nicht
                    aktualisiert. Folgende URL wurde im Deployment konfiguriert:{" "}
                    <b>{API_URL}</b>{" "}
                  </Alert>
                </>
              )}
            </Form.Group>
            <>
              <Button
                variant="primary"
                type="submit"
                disabled={loadingUrl || initialLoading}
                className="mb-3"
              >
                Ändern
              </Button>{" "}
              <Button
                variant="secondary"
                disabled={initialLoading}
                className="mb-3"
                onClick={() => handleConnectivityTest(organizationUrl)}
              >
                Verbindung prüfen
              </Button>
            </>
            {UrlResponseMessage && (
              <Alert variant={UrlAlertType}>{UrlResponseMessage}</Alert>
            )}
          </Form>

          <hr className="mb-4" />

          <h3>Verbindung zu anderen Organisationen</h3>
          <Alert variant="warning">
            Noch in der Entwicklung - Es handelt sich um Demodaten
          </Alert>
          <p>
            Damit sich Lehrkräfte über die eigene Schulorganisation mit Vorlagen
            für Sessions austauschen können, besteht die Möglichkeit die
            Organisation mit anderen SRL Servern zu verbinden.
          </p>
          <Button
            className="my-3"
            onClick={() =>
              alert(
                "Befindet sich noch in der Entwicklung und ist noch nicht verfügbar."
              )
            }
          >
            Partner Organisation hinzufügen
          </Button>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Partner Organisation</th>
                <th>API Url</th>
                <th>Status</th>
                <th>Aktion</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="my-2">
                  <p className="my-2">1</p>
                </td>
                <td>
                  <p className="my-2">Gesamtschule Bonn</p>
                </td>
                <td>
                  <p className="my-2">https://partner-schule-demo.de/srl-api</p>
                </td>
                <td>
                  <Alert className="my-2" variant="info">
                    Ausstehend
                  </Alert>
                </td>
                <td>
                  <Button
                    className="my-2"
                    onClick={() =>
                      alert(
                        "Befindet sich noch in der Entwicklung und ist noch nicht verfügbar."
                      )
                    }
                  >
                    Ändern
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default SettingsOrganization;
