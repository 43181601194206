import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import LogsList from "./LogsList";

import SideNavigation from "./SideNavigation";

function Logs() {
  return (
    <Container>
      <Row>
        <Col sm={4}>
          <SideNavigation />
        </Col>
        <Col sm={8}>
          <h1>Logs</h1>
          <p>
            Hier sehen Sie die Liste an Logs. Ein Log bezeichnet eine Durchführung einer Sessions und die dabei generierten Daten.
          </p>

          <LogsList />
        </Col>
      </Row>
    </Container>
  );
}

export default Logs;
