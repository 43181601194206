/**
 * Validates if given string is valid mail address with the help of RegEx
 * @param {String} sMailAddress
 * @returns {Boolean} Indicates if provided mail address is valid
 */
function isValidEmailAddress(sMailAddress) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(sMailAddress);
}

/**
 * Creates a image from the provided qrCode and downloads the image, the image name consists of the provided first and surname
 * @param {String} qrCode Base64 encoded QR code of a student
 * @param {String} firstname Firstname of the student
 * @param {String} surname Surname of the student
 */
function handleQrCodeDownload(qrCode, firstname, surname) {
  var a = document.createElement("a"); //Create <a>
  a.href = qrCode; //Image Base64 Goes here
  a.download = "SRL_APP_QR_CODE_" + firstname + "_" + surname + ".png"; //File name Here
  a.click(); //Downloaded file
}

module.exports = {
  isValidEmailAddress,
  handleQrCodeDownload,
};
