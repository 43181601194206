import React from "react";
import {
  Container,
  Button,
  Alert,
  Table
} from "react-bootstrap";
import { useQuery, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";

const USER_LIST = gql`
  {
    platformUsers {
      id
      firstname
      surname
      email
      role
      organization {
        name
      }
    }
  }
`;

function handleUserEdit(oHistory, oplatformUsers){  
  oHistory.push({
      pathname: "/user-details/" + oplatformUsers.id,
      state: { platformUsers: oplatformUsers}
    });
}

function UserList() {
  const oHistory = useHistory()
  const { data, error } = useQuery(USER_LIST);

  return (
    <Container className="mb-3">
      <h2>Benutzer</h2>
      <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Vorname</th>
                <th>Nachname</th>
                <th>E-Mail-Adresse</th>
                <th>Rolle</th>
                {/* <th>Organisation</th> */}
                <th>Aktion</th>
              </tr>
            </thead>
            <tbody>
            {data && (
              <>
              {data.platformUsers.map((platformUsers) => (
                <tr>
                  <td>{platformUsers.firstname}</td>
                  <td>{platformUsers.surname}</td>
                  <td>{platformUsers.email}</td>
                  <td>{platformUsers.role}</td>
                  {/* <td>{platformUsers.organization.name}</td> */}
                  <td><Button onClick={()=> handleUserEdit(oHistory, platformUsers)}>Details</Button></td>
                </tr>
                ))
              }
              </>
            )}
            </tbody>
          </Table>
          {error && <Alert variant="danger">{error.message}</Alert>}
    </Container>
  );
}
export default UserList;
