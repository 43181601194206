import React from "react";
import { Container, Col, Row, Form, Button, Alert } from "react-bootstrap";
import { useMutation, gql } from "@apollo/client";
import { isValidEmailAddress } from "../utils";

function Registration() {
  const REGISTRATION = gql`
    mutation mutation(
      $email: String!
      $password: String!
      $firstname: String!
      $surname: String!
      $role: String!
    ) {
      signup(
        email: $email
        password: $password
        firstname: $firstname
        surname: $surname
        role: $role
      ) {
        platformUser {
          id
        }
      }
    }
  `;
  const [responseMessage, setResponseMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("warning");
  const [firstname, setFirstname] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [role] = React.useState("NOROLE");
  const [mail, setMail] = React.useState("");
  const [mailAgain, setMailAgain] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordAgain, setPasswordAgain] = React.useState("");
  const [register, { loading }] = useMutation(REGISTRATION, {
    onCompleted: (data) => {
      //Clear input
      setFirstname("");
      setSurname("");
      // setRole("INSTRUCTOR");
      setMail("");
      setMailAgain("");
      setPassword("");
      setPasswordAgain("");

      setAlertType("success");
      setResponseMessage("Account erfolgreich angelegt.");
    },
    onError: (error) => {
      console.error("Error during registration attempt", error);
      setAlertType("danger");
      setResponseMessage(error.message);
    },
  });

  function handleRegistration(event) {
    event.preventDefault();
    if (
      firstname !== "" &&
      surname !== "" &&
      mail !== "" &&
      mailAgain !== "" &&
      password !== "" &&
      passwordAgain !== ""
    ) {
      if (mail !== mailAgain) {
        setAlertType("warning");
        setResponseMessage(
          "Die eingegebenen E-Mail-Adressen stimmen nicht überein"
        );
      } else if (password !== passwordAgain) {
        setAlertType("warning");
        setResponseMessage("Die eingegebenen Passwörter stimmen nicht überein");
      } else if (!isValidEmailAddress(mail)) {
        setAlertType("danger");
        setResponseMessage("Bei der eingegebenen E-Mail-Adresse handelt es sich um kein gültiges Format.");
      } else {
        register({
          variables: {
            firstname: firstname,
            surname: surname,
            role: role,
            email: mail,
            password: password,
          },
        });
      }
    } else {
      setAlertType("warning");
      setResponseMessage("Bitte darauf achten, alle Eingabefelder zu füllen.");
    }
  }

  return (
    <Container>
      <Row>
        <Col></Col>
        <Col xs={7}>
          <h1>Registrierung</h1>
          <p>
            Hier können Sie sich registrieren, um das SRL System zu nutzen. Der
            Admin des Systems wird Sie nach der Registrierung als Lehrkraft,
            Forscher:in oder als Administrator:in im System freischalten. Danach
            sehen Sie rollenspezifische Inhalte im Web Portal.
          </p>
          <hr className="mb-4" />
          {responseMessage && (
            <Alert variant={alertType}>
              {responseMessage}
              {alertType === "success" && (
                <Alert.Link href="/login"> Hier geht es zum Login</Alert.Link>
              )}
            </Alert>
          )}
          <Form onSubmit={handleRegistration}>
            <Form.Group className="mb-3" controlId="formBasicFirstname">
              <Form.Label>Vorname</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                value={firstname}
                onChange={(event) => setFirstname(event.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicSurname">
              <Form.Label>Nachname</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                value={surname}
                onChange={(event) => setSurname(event.target.value)}
              />
            </Form.Group>

            {/* <Form.Group controlId="formBasicRole">
              <Form.Label>Rolle</Form.Label>
              <Form.Control required as="select" value={role} onChange={(event) => setRole(event.target.value)}>
                <option value="INSTRUCTOR">Lehrkraft</option>
                <option value="RESEARCHER">Forscherin/Forscher</option>
              </Form.Control>
            </Form.Group> */}

            <Form.Group className="mb-3" controlId="formBasicMail">
              <Form.Label>E-Mail-Adresse</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="name@domain.de"
                value={mail}
                onChange={(event) => setMail(event.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmailAgain">
              <Form.Label>E-Mail-Adresse wiederholen</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="name@domain.de"
                value={mailAgain}
                onChange={(event) => setMailAgain(event.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Passwort</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Passwort"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPasswordAgain">
              <Form.Label>Passwort wiederholen</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Passwort wiederholen"
                value={passwordAgain}
                onChange={(event) => setPasswordAgain(event.target.value)}
              />
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              disabled={loading}
              className="mb-3"
            >
              Registrieren
            </Button>
          </Form>
          <hr className="mb-4" />
          <Button className="mb-5" variant="secondary" href="/">
            Startseite
          </Button>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}

export default Registration;
