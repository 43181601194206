import React from "react";
import { Table, Container, Col, Row, Alert } from "react-bootstrap";
import { useQuery, gql } from "@apollo/client";

export default function SystemStatistics({ organizationName, purpose }) {
  const role = localStorage.getItem("role");

  const GET_NUMBER_OF_STUDENTS = gql`
    query getNumberOfStudents($organizationId: ID!) {
      getNumberOfStudents(organizationId: $organizationId) {
        all
        activated
      }
    }
  `;

  const GET_NUMBER_OF_INSTRUCTORS = gql`
    query getNumberOfInstructors($organizationId: ID!) {
      getNumberOfInstructors(organizationId: $organizationId)
    }
  `;

  const GET_NUMBER_OF_RESEARCHERS = gql`
    query getNumberOfInstructors($organizationId: ID!) {
      getNumberOfResearchers(organizationId: $organizationId)
    }
  `;

  const GET_NUMBER_OF_USERS_WITHOUT_ROLE = gql`
    query getNumberOfUserWithoutRole($organizationId: ID!) {
      getNumberOfUserWithoutRole(organizationId: $organizationId)
    }
  `;

  const GET_NUMBER_OF_LOGS = gql`
    query getNumberOfLogs($organizationId: ID!) {
      getNumberOfLogs(organizationId: $organizationId)
    }
  `;

  const GET_NUMBER_OF_SESSIONS = gql`
    query getNumberOfSessions($organizationId: ID!) {
      getNumberOfSessions(organizationId: $organizationId)
    }
  `;

  const { data: numberOfStudents } = useQuery(GET_NUMBER_OF_STUDENTS, {
    variables: { organizationId: localStorage.getItem("organization_id") },
  });

  const { data: numberOfInstructors } = useQuery(GET_NUMBER_OF_INSTRUCTORS, {
    variables: { organizationId: localStorage.getItem("organization_id") },
  });

  const { data: numberOfResearchers } = useQuery(GET_NUMBER_OF_RESEARCHERS, {
    variables: { organizationId: localStorage.getItem("organization_id") },
  });

  const { data: numberOfUsersWithoutRole } = useQuery(
    GET_NUMBER_OF_USERS_WITHOUT_ROLE,
    {
      variables: { organizationId: localStorage.getItem("organization_id") },
    }
  );

  const { data: numberOfLogs } = useQuery(GET_NUMBER_OF_LOGS, {
    variables: { organizationId: localStorage.getItem("organization_id") },
  });

  const { data: numberOfSessions } = useQuery(GET_NUMBER_OF_SESSIONS, {
    variables: { organizationId: localStorage.getItem("organization_id") },
  });

  function renderStatistics() {
    switch (role) {
      case "ADMIN":
        if (
          numberOfStudents &&
          numberOfInstructors &&
          numberOfResearchers &&
          numberOfUsersWithoutRole &&
          numberOfLogs &&
          numberOfSessions
        ) {
          return renderAdmin();
        } else {
          return (
            <Alert className="my-2" variant="info">
              Statistiken werden geladen
            </Alert>
          );
        }
      case "INSTRUCTOR":
        if (numberOfStudents && numberOfLogs && numberOfSessions) {
          return renderInstructor();
        } else {
          return (
            <Alert className="my-2" variant="info">
              Statistiken werden geladen
            </Alert>
          );
        }
      case "RESEARCHER":
        if (numberOfStudents && numberOfLogs && numberOfSessions) {
          return renderResearcher();
        } else {
          return (
            <Alert className="my-2" variant="info">
              Statistiken werden geladen
            </Alert>
          );
        }
      default:
        return renderDefault();
    }
  }

  function renderDefault() {
    return (
      <Container>
        <Alert className="my-2" variant="warning">
          Sie haben keine Berechtigungen Statistiken zu sehen. Nur die Rollen:
          ADMIN, INSTRUCTOR und RESEARCHER können spezifische System-Statistiken
          einsehen.
        </Alert>
      </Container>
    );
  }

  function renderAdmin() {
    return (
      <Container>
        <Col>
          <Row>
            <h3>Benutzer</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Schülerinnen und Schüler</th>
                  <th>Lehrkräfte</th>
                  <th>Forscherinnen & Forscher</th>
                  <th>Ohne Rolle</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {numberOfStudents.getNumberOfStudents.all}, gesamt
                    <br />
                    {numberOfStudents.getNumberOfStudents.activated}, in der App
                    aktiviert
                  </td>
                  <td>{numberOfInstructors.getNumberOfInstructors}</td>
                  <td>{numberOfResearchers.getNumberOfResearchers}</td>
                  <td>{numberOfUsersWithoutRole.getNumberOfUserWithoutRole}</td>
                </tr>
              </tbody>
            </Table>
          </Row>
          <Row>
            <h3>Sessions</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Erstellte Sessions</th>
                  <th>Anzahl an Logs </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{numberOfSessions.getNumberOfSessions}</td>
                  <td>{numberOfLogs.getNumberOfLogs}</td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </Col>
      </Container>
    );
  }

  function renderInstructor() {
    return (
      <Container>
        <Col>
          <Row>
            <h3>Benutzer</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Schülerinnen und Schüler</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {numberOfStudents.getNumberOfStudents.all}, gesamt
                    <br />
                    {numberOfStudents.getNumberOfStudents.activated}, in der App
                    aktiviert
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
          <Row>
            <h3>Sessions</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Erstellte Sessions</th>
                  <th>Anzahl an Logs </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{numberOfSessions.getNumberOfSessions}</td>
                  <td>{numberOfLogs.getNumberOfLogs}</td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </Col>
      </Container>
    );
  }

  function renderResearcher() {
    return (
      <Container>
        <Col>
          <Row>
            <h3>Benutzer</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Schülerinnen und Schüler</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {numberOfStudents.getNumberOfStudents.all}, gesamt
                    <br />
                    {numberOfStudents.getNumberOfStudents.activated}, in der App
                    aktiviert
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
          <Row>
            <h3>Sessions</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Erstellte Sessions</th>
                  <th>Anzahl an Logs </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{numberOfSessions.getNumberOfSessions}</td>
                  <td>{numberOfLogs.getNumberOfLogs}</td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </Col>
      </Container>
    );
  }
  return (
    <Container>
      {
        (purpose === "data-export" ? (
          <h2>Daten Umfang</h2>
        ) : (
          <h2>System-Statistiken: {organizationName}</h2>
        ))
      }

      <hr className="mb-4" />
      <Row>
        <Col>{renderStatistics()}</Col>
      </Row>
    </Container>
  );
}
