import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import TopNavigation from './TopNavigation'

import Welcome from "./Welcome"
import Login from "./Login"
import Registration from "./Registration"
import Home from "./Home"
import Students from "./Students";
import AddStudent from "./AddStudent";
import StudentDetails from "./StudentDetails";
import ChangeMail from "./ChangeMail";
import ChangePassword from "./ChangePassword";
import User from "./User";
import UserDetails from "./UserDetails";
import DataExport from "./DataExport";
import SettingsOrganization from "./SettingsOrganization";
import Sessions from "./Sessions";
import SessionDetails from "./SessionDetails";
import Logs from "./Logs";
import LogDetails from "./LogDetails";

export default class AppRouter extends React.Component {
    render() {
        return (
            <div>
                <TopNavigation />
                <Router>
                    <Switch>
                        <Route exact path="/" component={Welcome} />
                        <Route path="/login" component={Login} />
                        <Route path="/register" component={Registration} />
                        <Route path="/home" component={Home} />
                        <Route path="/students" component={Students} />
                        <Route path="/add-student" component={AddStudent}/>
                        <Route path="/student-details/:id" component={StudentDetails}/>
                        <Route path="/change-mail" component={ChangeMail}/>
                        <Route path="/change-password" component={ChangePassword}/>
                        <Route path="/user" component={User}/>
                        <Route path="/user-details/:id" component={UserDetails}/>
                        <Route path="/data-export" component={DataExport}/>
                        <Route path="/settings-organization" component={SettingsOrganization}/>
                        <Route path="/sessions" component={Sessions} />
                        <Route path="/session-details/:id" component={SessionDetails}/>
                        <Route path="/logs" component={Logs} />
                        <Route path="/log-details/:id" component={LogDetails}/>
                    </Switch>
                </Router>
            </div>
        )
    }
}
