import React from "react";
import { Container, Navbar } from "react-bootstrap";

function TopNavigation() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="mb-5">
      <Container>
        <Navbar.Brand>SRL - Web Portal</Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav activeKey={window.location.pathname}>
            <Nav.Link href="/">Startseite</Nav.Link>
            <Nav.Link href="/login">Einloggen</Nav.Link>
            <Nav.Link href="/register">Registrieren</Nav.Link>
          </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
}

export default TopNavigation;
