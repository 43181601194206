import React, { useEffect } from "react";
import {
  Container,
  Button,
  Alert,
  Form,
  Card,
  Col,
  Row,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import { NO_USER_BASE64 } from "../assets/noUser";
import { handleQrCodeDownload } from "../utils";

function isEmpty(sValue) {
  if (sValue === "") {
    return true;
  } else {
    return false;
  }
}

function convertToBoolean(sInput) {
  switch (sInput) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return sInput;
  }
}

function StudentDetails() {
  const UPDATE_STUDENT = gql`
    mutation changeStudent(
      $id: ID!
      $firstname: String!
      $surname: String!
      $activated: Boolean!
    ) {
      changeStudent(
        id: $id
        firstname: $firstname
        surname: $surname
        activated: $activated
      ) {
        firstname
        surname
        activated
      }
    }
  `;
  const DELETE_STUDENT = gql`
    mutation deleteStudent($id: ID!) {
      deleteStudent(id: $id)
    }
  `;
  const [validNavigation, setValidNavigation] = React.useState(true);
  const location = useLocation();
  // const noUserImage = "
  const [id, setId] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [activated, setActivated] = React.useState("");
  const [qrCode, setQrCode] = React.useState("");
  const [responseMessage, setResponseMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [disabled, setDisabled] = React.useState(false);
  const [updateStudent, { loading }] = useMutation(UPDATE_STUDENT, {
    onCompleted: (data) => {
      setAlertType("success");
      setResponseMessage(
        "Die Änderungen wurden gespeichert. Der QR code hat sich nicht verändert"
      );
    },
    onError: (error) => {
      console.error("Error during student update attempt", error);
      setAlertType("danger");
      setResponseMessage(error.message);
    },
  });
  const [deleteStudent, { loading: loadingDeletion }] = useMutation(
    DELETE_STUDENT,
    {
      onCompleted: (data) => {
        window.history.replaceState({}, "");
        setFirstname("");
        setSurname("");
        setQrCode(NO_USER_BASE64);
        setActivated("false");
        setDisabled(true);

        setAlertType("success");
        setResponseMessage("Löschung erfolgreich");
      },
      onError: (error) => {
        console.error("Error during student deletion attempt", error);
        setAlertType("danger");
        setResponseMessage(error.message);
      },
    }
  );

  useEffect(() => {
    try {
      const student = location.state.student;
      setId(student.id);
      setFirstname(student.firstname);
      setSurname(student.surname);
      setActivated(student.activated);
      setQrCode(student.qrCode);
    } catch (error) {
      console.error(error);
      setValidNavigation(false);
    }
  }, [location]);

  function handleUpdateUser(event) {
    event.preventDefault();

    //Check not empty
    try {
      [firstname, surname, activated].forEach((element) => {
        if (isEmpty(element)) {
          throw new Error("Alle Eingabefelder müssen ausgefüllt sein");
        }
      });
    } catch (error) {
      setAlertType("danger");
      setResponseMessage(error.message);
      return;
    }

    updateStudent({
      variables: {
        id: id,
        firstname: firstname,
        surname: surname,
        activated: convertToBoolean(activated),
      },
    });
  }

  function handleDelete() {
    const bConfirmDeletion = window.confirm(
      "Soll " + firstname + " " + surname + " endgültig gelöscht werden?"
    );
    if (bConfirmDeletion) {
      deleteStudent({
        variables: {
          id: id,
        },
      });
    } else {
      setAlertType("info");
      setResponseMessage("Löschung wurde abgebrochen");
    }
  }

  if (validNavigation) {
    return (
      <Container>
        <h2>
          Detailansicht für {firstname} {surname}
        </h2>
        <p>
          Hier sehen Sie die Details zu einer Schülerin bzw. eines Schülers. Sie
          können Änderungen vornehmen oder den QR-Code herunterladen. Für eine
          bessere Zuordnung enthält der Dateiname des QR-Codes den Namen der
          Schülerin bzw. des Schülers. Der QR-Code wird benötigt, damit sich die
          Schülerinnen und Schüler an der mobilen SRL App einloggen können.
        </p>
        <hr className="mb-4" />
        <Row>
          <Col>
            <Form onSubmit={handleUpdateUser}>
              <Form.Group className="mb-3" controlId="formBasicFirstname">
                <Form.Label>Vorname</Form.Label>
                <Form.Control
                  disabled={disabled}
                  required
                  type="text"
                  placeholder=""
                  value={firstname}
                  onChange={(event) => setFirstname(event.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicSurname">
                <Form.Label>Nachname</Form.Label>
                <Form.Control
                  disabled={disabled}
                  required
                  type="text"
                  placeholder=""
                  value={surname}
                  onChange={(event) => setSurname(event.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicSurname">
                <Form.Label>Aktivierung</Form.Label>
                <Form.Control
                  disabled={disabled}
                  as="select"
                  value={activated}
                  defaultChecked={true}
                  onChange={(event) => {
                    setActivated(event.target.value);
                  }}
                >
                  <option value="true">Aktiviert</option>
                  <option value="false">Nicht aktiviert</option>
                </Form.Control>
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                disabled={loading || disabled}
                className="mb-3"
              >
                Speichern
              </Button>{" "}
              <Button
                variant="danger"
                className="mb-3"
                disabled={loadingDeletion || disabled}
                onClick={handleDelete}
              >
                Löschen
              </Button>{" "}
              {responseMessage && (
                <Alert variant={alertType}>{responseMessage}</Alert>
              )}
            </Form>
          </Col>
          <Col>
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={qrCode}></Card.Img>
              <Card.Body style={{ textAlign: "center" }}>
                <Card.Title>
                  {firstname} {surname}
                </Card.Title>
                <Card.Link>
                  <Button
                    onClick={() =>
                      handleQrCodeDownload(qrCode, firstname, surname)
                    }
                  >
                    Download
                  </Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <hr className="mb-4" />
        <Button className="mb-4" href="/students">
          Zurück
        </Button>
      </Container>
    );
  } else {
    return (
      <Container>
        <Alert variant="danger">
          <Alert.Heading>Ups! Ungültige Navigation</Alert.Heading>
          <p>
            <Alert.Link href="/">Hier</Alert.Link> geht es zurück zur Startseite
          </p>
        </Alert>
      </Container>
    );
  }
}

export default StudentDetails;
