import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import SideNavigation from "./SideNavigation";
import UserList from "./UserList";

function User() {
    return (
        <Container>
            <Row>
                <Col sm={4}>
                    <SideNavigation />
                </Col>
                <Col sm={8}>
                    <h1>Benutzer am System</h1>
                    <p>Hier sind alle Benutzer am System aufgelistet (exklusive Schülerinnen und Schüler).</p>
                    <UserList />
                </Col>
            </Row>
        </Container>
    )
}

export default User;