import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import SessionsList from "./SessionsList";

import SideNavigation from "./SideNavigation";

function Sessions() {
  return (
    <Container>
      <Row>
        <Col sm={4}>
          <SideNavigation />
        </Col>
        <Col sm={8}>
          <h1>Sessions</h1>
          <p>
            Hier sehen Sie die Liste an Sessions, welche Sie in der App erstellt
            und synchronisiert haben.
          </p>

          <SessionsList />
        </Col>
      </Row>
    </Container>
  );
}

export default Sessions;
