import React from "react";
import { Container, Button, Alert, Table } from "react-bootstrap";
import { useQuery, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";

const LOGS_LIST = gql`
  query getLogBySessionCreatedBy($createdBy: ID!, $organizationId: ID!) {
    getLogBySessionCreatedBy(
      createdBy: $createdBy
      organizationId: $organizationId
    ) {
      id
      session {
        title
      }
      student {
        firstname
        surname
      }
      organization {
        id
        name
      }
      sessionPreStart
      sessionPreEnd
      sessionDuringStart
      sessionDuringEnd
      sessionPostStart
      sessionPostEnd
      logs
    }
  }
`;

function handleLogDetails(oHistory, oLog) {
  oHistory.push({
    pathname: "/log-details/" + oLog.id,
    state: { log: oLog },
  });
}

function LogsList() {
  const oHistory = useHistory();
  const { data, error } = useQuery(LOGS_LIST, {
    variables: {
      createdBy: localStorage.getItem("id"),
      organizationId: localStorage.getItem("organization_id"),
    },
  });

  return (
    <Container className="mb-3">
      <hr className="mb-4" />
      <h2>Verfügbare Logs</h2>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Session</th>
            <th>Durchgeführt von</th>
            <th>Durchgeführt am</th>
            <th>Aktion</th>
          </tr>
        </thead>
        <tbody>
          {data && data.getLogBySessionCreatedBy.length > 0 ?(
            <>
              {data.getLogBySessionCreatedBy.map((log) => (
                <tr>
                  <td>{log.session.title}</td>
                  <td>{log.student.firstname} {log.student.surname}</td>
                  <td>{log.sessionPreStart}</td>
                  <td>
                    <Button onClick={() => handleLogDetails(oHistory, log)}>
                      Details
                    </Button>
                  </td>
                </tr>
              ))}
            </>
          ): (
            <tr>
            <td colSpan="4">Es sind noch keine Logs verfügbar</td>
          </tr>
          )}
        </tbody>
      </Table>
      {error && <Alert variant="danger">{error.message}</Alert>}
    </Container>
  );
}
export default LogsList;
