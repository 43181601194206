import React from "react";
import { Container, Button, Alert, Table } from "react-bootstrap";
import { useQuery, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";

const STUDENTS_LIST = gql`
  {
    students {
      id
      firstname
      surname
      activated
      qrCode
    }
  }
`;

/**
 * Maps boolean from API to understanable expression for the field 'activated' in student
 * @param {String} sStatus
 * @returns Meaning of field 'activated'
 */
function studentActivatedStatusMapping(sStatus) {
  switch (sStatus) {
    case "true":
      return "In der App aktiviert";
    case "false":
      return "Noch nicht in der App aktiviert";
    default:
      return "Systemfehler";
  }
}

function handleStudentDetails(oHistory, oStudent) {
  oHistory.push({
    pathname: "/student-details/" + oStudent.id,
    state: { student: oStudent },
  });
}

function StudentList() {
  const oHistory = useHistory();
  const { data, error } = useQuery(STUDENTS_LIST);

  return (
    <Container className="mb-3">
      <hr className="mb-4" />
      <h2>Angelegte Teilnehmer:innen</h2>
      <Button className="mb-4" href="/add-student">
        Teilnehmer:in hinzufügen
      </Button>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Vorname</th>
            <th>Nachname</th>
            <th>Status</th>
            {/* <th>Organisation</th> */}
            <th>Aktion</th>
          </tr>
        </thead>
        <tbody>
          {data && data.students.length > 0 ? (
            <>
              {data.students.map((student) => (
                <tr>
                  <td>{student.firstname}</td>
                  <td>{student.surname}</td>
                  <td>{studentActivatedStatusMapping(student.activated)}</td>
                  <td>
                    <Button
                      onClick={() => handleStudentDetails(oHistory, student)}
                    >
                      Details
                    </Button>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan="4">
                Es wurden noch keine Schülerinnen und Schüler in der Organisation angelegt
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {error && <Alert variant="danger">{error.message}</Alert>}
    </Container>
  );
}
export default StudentList;
