import React, { useEffect } from "react";
import {
  Container,
  Button,
  Alert,
  Form,
  Col,
  Row,
  Card,
  ListGroup,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";

function SessionDetails() {
  const [validNavigation, setValidNavigation] = React.useState(true);
  const location = useLocation();

  // const [id, setId] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [elements, setElements] = React.useState("");
  const [tags, setTags] = React.useState("");
  const [createdAt, setCreatedAt] = React.useState("");
  const [studentsList, setStudentsList] = React.useState();
  const [organizationName, setOrganizationName] = React.useState();
  const [createdBy, setCreatedBy] = React.useState();

  // const [responseMessage, setResponseMessage] = React.useState("");
  // const [alertType, setAlertType] = React.useState("");
  // const [disabled, setDisabled] = React.useState(true);

  useEffect(() => {
    try {
      const session = location.state.session;
      // setId(session.id);
      setTitle(session.title);
      setElements(JSON.parse(session.elements));
      setTags(JSON.parse(session.tags));
      setCreatedAt(session.createdAt);
      setStudentsList(session.student);
      setOrganizationName(session.organization.name)
      setCreatedBy(session.createdBy.firstname + " " + session.createdBy.surname)
    } catch (error) {
      console.error(error);
      setValidNavigation(false);
    }
  }, [location]);

  if (validNavigation) {
    return (
      <Container>
        <h2>Detailansicht für Session: {title}</h2>
        <p>
          Hier sehen Sie die Details zu einer von Ihnen erstellten Session.
        </p>
        <hr className="mb-4" />
        <Row>
          <Col>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicTitle">
                <h4>Titel</h4>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  placeholder=""
                  value={title}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCreatedBy">
                <h4>Erstellt von</h4>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  placeholder=""
                  value={createdBy}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCreatedAt">
                <h4>Erstell am</h4>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  placeholder=""
                  value={createdAt}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicOrganization">
                <h4>Organisation</h4>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  placeholder=""
                  value={organizationName}
                />
              </Form.Group>
              
              <h4 className="my-4">Tags</h4>
              <ListGroup  className="my-4">
                  {typeof tags === "object" && tags.length > 0 ? (
                    tags.map((tag) => {
                      return (<ListGroup.Item>{tag}</ListGroup.Item>)
                    })
                  ) : (
                    <Alert variant="info">Der Session wurden keine Tags hinzugefügt</Alert>
                  )}
                </ListGroup>
              <h4>Zugewiesene Schülerinnen & Schüler</h4>
              <ListGroup>
                {typeof studentsList === "object" && studentsList.length > 0 ? (
                  studentsList.map((student) => {
                    return (
                      <ListGroup.Item>
                        {student.firstname} {student.surname}
                      </ListGroup.Item>
                    );
                  })
                ) : (
                  <Alert variant="info">
                  Es wurden keine Schülerinnen oder Schüler der Session
                    zugewiesen
                </Alert>
                )}
              </ListGroup>
              <h4 className="my-4">Elemente</h4>
              <h5 className="my-3">Einleitung</h5>
              {typeof elements.sessionElementsPre === "object" &&
              elements.sessionElementsPre.length > 0 ? (
                elements.sessionElementsPre.map((element) => {
                  return (
                    <Card className="my-3">
                      <Card.Body>
                        {/* <Card.Title>{element.type}</Card.Title> */}
                        <Card.Body>{element.type}</Card.Body>
                      </Card.Body>
                    </Card>
                  );
                })
              ) : (
                <Alert variant="info">
                  Keine Elemente in dieser Sessionphase
                </Alert>
              )}

              <h5 className="my-3">Während der Session</h5>
              {typeof elements.sessionElementsDuring === "object" &&
              elements.sessionElementsDuring.length > 0 ? (
                elements.sessionElementsDuring.map((element) => {
                  return (
                    <Card className="my-3">
                      <Card.Body>
                        <Card.Body>{element.type}</Card.Body>
                      </Card.Body>
                    </Card>
                  );
                })
              ) : (
                <Alert variant="info">
                  Keine Elemente in dieser Sessionphase
                </Alert>
              )}

              <h5 className="my-3">Evaluation</h5>
              {typeof elements.sessionElementsPost === "object" &&
              elements.sessionElementsPost.length > 0 ? (
                elements.sessionElementsPost.map((element) => {
                  return (
                    <Card className="my-3">
                      <Card.Body>
                        <Card.Body>{element.type}</Card.Body>
                      </Card.Body>
                    </Card>
                  );
                })
              ) : (
                <Alert variant="info">
                  Keine Elemente in dieser Sessionphase
                </Alert>
              )}

              {/* {responseMessage && (
                <Alert variant={alertType}>{responseMessage}</Alert>
              )} */}
            </Form>
          </Col>
        </Row>
        <hr className="mb-4" />
        <Button className="mb-4" href="/sessions">
          Zurück
        </Button>
      </Container>
    );
  } else {
    return (
      <Container>
        <Alert variant="danger">
          <Alert.Heading>Ups! Ungültige Navigation</Alert.Heading>
          <p>
            <Alert.Link href="/">Hier</Alert.Link> geht es zurück zur Startseite
          </p>
        </Alert>
      </Container>
    );
  }
}

export default SessionDetails;
