import React from "react";
import { Container, Col, Row, Button, Alert } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { gql, useLazyQuery } from "@apollo/client";

import SideNavigation from "./SideNavigation";
import SystemStatistics from "./SystemStatistics";

function DataExport() {
  const GET_ALL_LOGS = gql`
    query getAllLogs($organizationId: ID!) {
      getAllLogs(organizationId: $organizationId) {
        id
        organization {
          id
        }
        session {
          id
        }
        title
        student {
          id
        }
        sessionPreStart
        sessionPreEnd
        sessionDuringStart
        sessionDuringEnd
        sessionPostStart
        sessionPostEnd
        logs
      }
    }
  `;

  let EXPORT = [];
  const timestamp = +new Date();
  const [responseMessage, setResponseMessage] = React.useState("");
  const [loaded, setLoaded] = React.useState(false);
  const [exportData, setExportData] = React.useState([]);

  const [getExport] = useLazyQuery(GET_ALL_LOGS, {
    onCompleted: (data) => {
      console.log(data);
      data.getAllLogs.forEach((log) => {
        EXPORT.push({
          log_id: log.id,
          organization_id: log.organization.id,
          session_id: log.session.id,
          session_name: log.title,
          student_id: log.student.id,
          session_pre_start: log.sessionPreStart,
          session_pre_end: log.sessionPreEnd,
          session_during_start: log.sessionDuringStart,
          session_during_end: log.sessionDuringEnd,
          session_post_start: log.sessionPostStart,
          session_post_end: log.sessionPostEnd,
          logs: log.logs,
        });
      });
      console.log(EXPORT);
      setExportData(EXPORT);
      setLoaded(true);
    },
    onError: (error) => {
      console.error("Error during login attempt", error);
      setResponseMessage(error.message); //TODO
    },
  });

  return (
    <Container>
      <Row>
        <Col sm={4}>
          <SideNavigation />
        </Col>
        <Col sm={8}>
          <h1>Daten Export</h1>
          <p>
            Hier können Sie die pseudoanonymisierten Daten aus der Organization
            als CSV herunterladen. Die einzelnen Spalten sind mit einem
            Semikolon getrennt. Der Datenexport enthält alle in der Organization
            verfügbaren Logs. Bei einem Log handelt sich um die geloggten
            Informationen beim Durchführe einer Session.
          </p>

          <Button
            className="my-3"
            onClick={() =>
              getExport({
                variables: {
                  organizationId: localStorage.getItem("organization_id"),
                },
              })
            }
          >
            Daten für Export vorbereiten
          </Button>
          {loaded && (
            <Container>
              <CSVLink
                data={exportData}
                separator={";"}
                filename={"SRL-App-Data-Export-" + timestamp + ".csv"}
              >
                <Button className="my-3">Daten herunterladen</Button>
              </CSVLink>
            </Container>
          )}
          {responseMessage && <Alert variant="danger">{responseMessage}</Alert>}
          <SystemStatistics purpose="data-export" />
        </Col>
      </Row>
    </Container>
  );
}

export default DataExport;
