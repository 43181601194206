import React from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import { useMutation, gql } from "@apollo/client";

import SideNavigation from "./SideNavigation";
import { isValidEmailAddress } from "../utils";

const UPDATE_MAIL = gql`
  mutation updateMail($oldMail: String!, $newMail: String!) {
    changeEmailAddress(oldEmailAddress: $oldMail, newEmailAddress: $newMail) {
      email
    }
  }
`;

function ChangeMail() {
  const [responseMessage, setResponseMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("warning");
  const [mailOld, setMailOld] = React.useState("");
  const [mailNew, setMailNew] = React.useState("");
  const [mailNewAgain, setMailNewAgain] = React.useState("");
  const [updateMail, { loading }] = useMutation(UPDATE_MAIL, {
    onCompleted: (data) => {
      setMailOld("");
      setMailNew("");
      setMailNewAgain("");
      setAlertType("success");
      setResponseMessage(
        "Die E-Mail-Adresse Ihres Account wurde erfolgreich geändert. Ihre neue E-Mail-Adresse: " +
          data.changeEmailAddress.email
      );
    },
    onError: (error) => {
      console.error("Error during mail change attempt", error);
      setAlertType("danger");
      setResponseMessage(error.message);
    },
  });

  function handleChangeMail(event) {
    event.preventDefault();
    if (mailNew !== mailNewAgain) {
      setAlertType("warning");
      setResponseMessage(
        "Die neu eingegebenen E-Mail-Adressen stimmen nicht überein"
      );
    } else if (!isValidEmailAddress(mailNew)) {
      setAlertType("warning");
      setResponseMessage(
        "Die neu eingegebene E-Mail-Adresse ist keine gültige E-Mail-Adresse"
      );
    } else {
      updateMail({
        variables: {
          oldMail: mailOld,
          newMail: mailNew,
        },
      });
    }
  }

  return (
    <Container>
      <Row>
        <Col sm={4}>
          <SideNavigation />
        </Col>
        <Col>
          <h2>E-Mail-Adresse ändern</h2>
          <p>Ändern Sie die E-Mail-Adresse Ihres Accounts.</p>
          <hr className="mb-4" />
          <Form onSubmit={handleChangeMail}>
            <Form.Group className="mb-3" controlId="formBasicMailOld">
              <Form.Label>Alte E-Mail-Adresse</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder=""
                value={mailOld}
                onChange={(event) => setMailOld(event.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicMailNew">
              <Form.Label>Neue E-Mail-Adresse</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder=""
                value={mailNew}
                onChange={(event) => setMailNew(event.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicMailNewAgain">
              <Form.Label>Neue E-Mail-Adresse wiederholen</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder=""
                value={mailNewAgain}
                onChange={(event) => setMailNewAgain(event.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={loading}
              className="mb-3"
            >
              E-Mail-Adresse ändern
            </Button>
            {responseMessage && (
              <Alert variant={alertType}>{responseMessage}</Alert>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ChangeMail;
