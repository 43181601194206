import React, { useEffect } from "react";
import {
  Container,
  Button,
  Alert,
  Form,
  Col,
  Row,
  Card,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";

function LogDetails() {
  const [validNavigation, setValidNavigation] = React.useState(true);
  const location = useLocation();

  const [sessionTitle, setSessionTitle] = React.useState("");
  const [organizationName, setOrganizationName] = React.useState();
  const [conductedBy,setConductedBy] = React.useState()
  const [sessionPreStart, setSessionPreStart] = React.useState()
  const [sessionPreEnd, setSessionPreEnd] = React.useState()
  const [sessionDuringStart, setSessionDuringStart] = React.useState()
  const [sessionDuringEnd, setSessionDuringEnd] = React.useState()
  const [sessionPostStart, setSessionPostStart] = React.useState()
  const [sessionPostEnd, setSessionPostEnd] = React.useState()
  const [logs, setLogs] = React.useState()

  // const [responseMessage, setResponseMessage] = React.useState("");
  // const [alertType, setAlertType] = React.useState("");
  // const [disabled, setDisabled] = React.useState(true);

  useEffect(() => {
    try {
      const log = location.state.log;
      setSessionTitle(log.session.title);
      setOrganizationName(log.organization.name)
      setConductedBy(log.student.firstname + " " + log.student.surname)
      setSessionPreStart(log.sessionPreStart);
      setSessionPreEnd(log.sessionPreEnd);
      setSessionDuringStart(log.sessionDuringStart);
      setSessionDuringEnd(log.sessionDuringEnd);
      setSessionPostStart(log.sessionPostStart);
      setSessionPostEnd(log.sessionPostEnd);
      setLogs(JSON.parse(log.logs))

    } catch (error) {
      console.error(error);
      setValidNavigation(false);
    }
  }, [location]);
  if (validNavigation) {
    return (
      <Container>
        <h2>Detailansicht für Log: {sessionTitle}</h2>
        <p>
          Hier sehen Sie die Details zu einer Durchführung (Log) eines Schülers.
        </p>
        <hr className="mb-4" />
        <Row>
          <Col>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicTitle">
                <h4>Titel</h4>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  placeholder=""
                  value={sessionTitle}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCreatedBy">
                <h4>Erstellt von</h4>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  placeholder=""
                  value={conductedBy}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicOrganization">
                <h4>Organisation</h4>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  placeholder=""
                  value={organizationName}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicOrganization">
                <h4>Pre-Sessionphase gestartet</h4>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  placeholder=""
                  value={sessionPreStart}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicOrganization">
                <h4>Pre-Sessionphase beendet</h4>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  placeholder=""
                  value={sessionPreEnd}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicOrganization">
                <h4>During-Sessionphase gestartet</h4>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  placeholder=""
                  value={sessionDuringStart}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicOrganization">
                <h4>During-Sessionphase beendet</h4>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  placeholder=""
                  value={sessionDuringEnd}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicOrganization">
                <h4>Post-Sessionphase gestartet</h4>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  placeholder=""
                  value={sessionPostStart}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicOrganization">
                <h4>Post-Sessionphase beendet</h4>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  placeholder=""
                  value={sessionPostEnd}
                />
              </Form.Group>
              
              <h4 className="my-4">Logs zu den Elementen der Session</h4>
              {logs && typeof logs === "object" && logs.length > 0 ? (
                logs.map((log) => {
                    
                  return (
                    <Card className="my-3">
                      <Card.Body>
                        <Card.Title>Element: {log.type}</Card.Title>
                        <Card.Body>Phase: {log.phase}</Card.Body>
                        <Card.Body>Logs: <code>{JSON.stringify(log.logs)}</code></Card.Body>
                      </Card.Body>
                    </Card>
                  );
                })
              ) : (
                <Alert variant="info">
                  Es sind keine Logs von Elementen vorhanden
                </Alert>
              )} 

              {/* {responseMessage && (
                <Alert variant={alertType}>{responseMessage}</Alert>
              )} */}
            </Form>
          </Col>
        </Row>
        <hr className="mb-4" />
        <Button className="mb-4" href="/logs">
          Zurück
        </Button>
      </Container>
    );
  } else {
    return (
      <Container>
        <Alert variant="danger">
          <Alert.Heading>Ups! Ungültige Navigation</Alert.Heading>
          <p>
            <Alert.Link href="/">Hier</Alert.Link> geht es zurück zur Startseite
          </p>
        </Alert>
      </Container>
    );
  }
}

export default LogDetails;
