import React, { useEffect, useState } from "react";
import { Container, Col, Row, Alert } from "react-bootstrap";
import SideNavigation from "./SideNavigation";
import { API_URL } from "./../config";
import { useQuery, gql } from "@apollo/client";
import SystemStatistics from "./SystemStatistics";

function Home() {
  const GET_PLATFORM_USER = gql`
  query platformUser($platfromUserId: ID!) {
    platformUser(id: $platfromUserId) {
      firstname
      surname
      role
      organization {
        name
      }
    }
  }
`;

  const [initialLoading, setInitialLoading] = React.useState(true);
  const { data } = useQuery(GET_PLATFORM_USER, {
    variables: { platfromUserId: localStorage.getItem("id")},
  });
  const [firstname, setFirstname] = useState();
  const [surname, setSurname] = useState();
  const [role, setRole] = useState();
  const [organizationName, setOrganizationName] = useState()
  const organizationUrl = localStorage.getItem("organization_system_url");

  useEffect(() => {
    if (data) {
      setFirstname(data.platformUser.firstname);
      setSurname(data.platformUser.surname);
      setRole(data.platformUser.role);
      setOrganizationName(data.platformUser.organization.name)
      setInitialLoading(false);
    }
  }, [data]);

  return (
    <Container>
      <Row>
        <Col sm={4}>
          <SideNavigation />
        </Col>
        <Col sm={8}>
          <h1>
            Willkommen {firstname} {surname}
          </h1>
          {initialLoading && <Alert variant="info">Daten werden geladen</Alert>}
          <p>
            Dies ist das Web Portal der SRL App. Hier können Sie Teilnehmerinnen
            & Teilnehmer an der Organisation registrieren, Auswertungen machen,
            Daten exportieren und Ihren Account verwalten.
          </p>
          {role === "ADMIN" && organizationUrl !== API_URL && (
            <>
              <Alert className="my-2" variant="danger">
                Die im Deployment konfigurierte API Url ist noch nicht für die
                Organiaztion aktualisiert. Folgende URL wurde im Deployment
                konfiguriert: <b>{API_URL}</b>. Bevor andere Nutzer das System
                nutzen, bitte unter 'Einstellungen Organisation' aktualisieren.
              </Alert>
            </>
          )}
          <SystemStatistics organizationName={organizationName}/>
        </Col>
      </Row>
    </Container>
  );
}
export default Home;
