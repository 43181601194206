import React from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import { useMutation, gql } from "@apollo/client";

import SideNavigation from "./SideNavigation";

const UPDATE_PASSWORD = gql`
  mutation updatePassword(
    $email: String!
    $passwordOld: String!
    $passwordNew: String!
  ) {
    changePassword(
      email: $email
      oldPassword: $passwordOld
      newPassword: $passwordNew
    ) {
      id
    }
  }
`;

function ChangePassword() {
  const [responseMessage, setResponseMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("warning");
  const [passwordOld, setPasswordOld] = React.useState("");
  const [passwordNew, setPasswordNew] = React.useState("");
  const [passwordNewAgain, setPasswordNewAgain] = React.useState("");
  const [changePassword, { loading }] = useMutation(UPDATE_PASSWORD, {
    onCompleted: (data) => {
      setPasswordOld("");
      setPasswordNew("");
      setPasswordNewAgain("");
      setAlertType("success");
      setResponseMessage(
        "Das Password Ihres Account wurde erfolgreich geändert."
      );
    },
    onError: (error) => {
      console.error("Error during password change attempt", error);
      setAlertType("danger");
      setResponseMessage(error.message);
    },
  });

  function handleChangePassword(event) {
    event.preventDefault();
    if (passwordNew !== passwordNewAgain) {
      setAlertType("warning");
      setResponseMessage(
        "Die eingegebenen neuen Passwörter stimmen nicht überein"
      );
    } else {
      changePassword({
        variables: {
          email: localStorage.getItem("email").toLowerCase(),
          passwordOld: passwordOld,
          passwordNew: passwordNew,
        },
      });
    }
  }

  return (
    <Container>
      <Row>
        <Col sm={4}>
          <SideNavigation />
        </Col>
        <Col>
          <h2>Password ändern</h2>
          <p>Ändern Sie das Passwort Ihres Accounts.</p>
          <hr className="mb-4" />
          <Form onSubmit={handleChangePassword}>
            <Form.Group className="mb-3" controlId="formBasicMailOld">
              <Form.Label>Altes Passwort</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder=""
                value={passwordOld}
                onChange={(event) => setPasswordOld(event.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicMailNew">
              <Form.Label>Neues Passwort</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder=""
                value={passwordNew}
                onChange={(event) => setPasswordNew(event.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicMailNewAgain">
              <Form.Label>Neues Passwort wiederholen</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder=""
                value={passwordNewAgain}
                onChange={(event) => setPasswordNewAgain(event.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={loading}
              className="mb-3"
            >
              Passwort ändern
            </Button>
            {responseMessage && (
              <Alert variant={alertType}>{responseMessage}</Alert>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ChangePassword;
