import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import SideNavigation from "./SideNavigation";
import StudentsList from "./StudentsList";

function Students() {
    return (
        <Container>
            <Row>
                <Col sm={4}>
                    <SideNavigation />
                </Col>
                <Col sm={8}>
                    <h1>Teilnehermerinnen und Teilnehmer</h1>
                    <p>Hier sehen Sie die Liste an verfügabren Teilnehmerinnen und Teilnehmer in der Organisation. Außerdem können weite Teilnehmerinnen und Teilnehmer der Organisation hinzugefügt werden.</p>
                
                    
                    <StudentsList />
                </Col>
            </Row>
        </Container>
    )
}

export default Students;