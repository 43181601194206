import React from "react";
import { Container, Col, Row, Form, Button, Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      platformUser {
        id
        role
        firstname
        surname
        organization {
          id
          systemUrl
        }
      }
    }
  }
`;

function Login() {
  const history = useHistory();
  const [responseMessage, setResponseMessage] = React.useState("");
  const [mail, setMail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted: (data) => {
      localStorage.setItem("email", mail);
      localStorage.setItem("token", data.login.token);
      localStorage.setItem("role", data.login.platformUser.role);
      localStorage.setItem("id", data.login.platformUser.id);
      localStorage.setItem("firstname", data.login.platformUser.firstname);
      localStorage.setItem("surname", data.login.platformUser.surname);
      localStorage.setItem(
        "organization_id",
        data.login.platformUser.organization.id
      );
      localStorage.setItem(
        "organization_system_url",
        data.login.platformUser.organization.systemUrl
      );
      history.push("/home");
    },
    onError: (error) => {
      console.error("Error during login attempt", error);
      setResponseMessage(error.message);
    },
  });

  function handleLogin(event) {
    event.preventDefault();
    login({ variables: { email: mail, password: password } });
  }

  return (
    <Container>
      <Row>
        <Col></Col>
        <Col xs={7}>
          <h1>Login</h1>
          <hr className="mb-4" />
          <Form onSubmit={handleLogin}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>E-Mail-Adresse</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="name@domain.de"
                onChange={(event) => setMail(event.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Passwort</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Passwort"
                onChange={(event) => setPassword(event.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={loading}
              className="mb-3"
            >
              Einloggen
            </Button>
            {responseMessage && (
              <Alert variant="warning">{responseMessage}</Alert>
            )}
          </Form>
          <hr className="mb-4" />
          <Button variant="secondary" href="/">
            Startseite
          </Button>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}

export default Login;
