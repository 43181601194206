import React from "react";
import { ListGroup, Container } from "react-bootstrap";

function SideNavigationRoleLinks() {
  const role = localStorage.getItem("role");

  switch (role) {
    case "INSTRUCTOR":
      return (
        <Container>
          <h3 className="mt-3 ml-2">Rollenspezifisch</h3>
          <ListGroup activeKey={window.location.pathname}>
            <ListGroup.Item action href="/students">
              Teilnehmerinnen & Teilnehmer
            </ListGroup.Item>
            <ListGroup.Item action href="/sessions">
              Sessions
            </ListGroup.Item>
            <ListGroup.Item action href="/logs">
              Logs / Durchführungen
            </ListGroup.Item>
          </ListGroup>
        </Container>
      );
    case "RESEARCHER":
      return (
        <Container>
          <h3 className="mt-3 ml-2">Rollenspezifisch</h3>
          <ListGroup activeKey={window.location.pathname}>
            <ListGroup.Item action href="/data-export">
              Daten export
            </ListGroup.Item>
          </ListGroup>
        </Container>
      );
    case "ADMIN":
      return (
        <Container>
          <h3 className="mt-3 ml-2">Rollenspezifisch</h3>
          <ListGroup activeKey={window.location.pathname}>
            <ListGroup.Item action href="/user">
              Benutzer
            </ListGroup.Item>
            <ListGroup.Item action href="/settings-organization">
              Einstellungen Organisation
            </ListGroup.Item>
          </ListGroup>
        </Container>
      );
    default:
      return null;
  }
}

export default SideNavigationRoleLinks;
