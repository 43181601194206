
import React from "react";
import { Button, Col, Container, Jumbotron, Row, Alert} from 'react-bootstrap';

export default class Welcome extends React.Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <Jumbotron>
                            <h1>Willkommen im SRL Web Portal</h1>
                            <p>Dieses Web Portal dient dazu, dass Lehrkräfte Teilnehmerinnen und Teilnehmer in der Organization anlegen können.</p>
                            <>
                                <Button variant="primary" href="/login">Einloggen</Button>{' '}
                                <Button variant="secondary" href="/register">Registrieren</Button>
                            </>
                        </Jumbotron>
                        <Alert variant="info">Ein Projekt von <Alert.Link href="https://www.linkedin.com/in/david-mitrus-70a756134/">David Mitrus</Alert.Link> und <Alert.Link href="https://www.linkedin.com/in/maltehain/">Malte Hain</Alert.Link> im Rahmen unserer Masterarbeit an der Universität zu Köln 2021</Alert>
                    </Col>
                </Row>
            </Container>
        )
    }
}