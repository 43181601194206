import React from "react";
import { Container, Col, Row, ListGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SideNavigationRoleLinks from "./SideNavigationRoleLinks";

function SideNavigation() {
  const oHistory = useHistory();

  function logout() {
    localStorage.clear();
    oHistory.push("/");
  }

  return (
    <Container>
      <Row>
        <Col>
          <h3 className="mt-3 ml-2">Allgemein</h3>
          <ListGroup activeKey={window.location.pathname}>
            <ListGroup.Item action href="/home">
              Home
            </ListGroup.Item>
            <ListGroup.Item action href="/change-password">
              Passwort ändern
            </ListGroup.Item>
            <ListGroup.Item action href="/change-mail">
              E-Mail-Adresse ändern
            </ListGroup.Item>
            <ListGroup.Item action onClick={logout}>
              Ausloggen
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <SideNavigationRoleLinks />
      </Row>
    </Container>
  );
}

export default SideNavigation;
